<nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
  <div class="container-fluid">
    <!-- Brand -->
    <a class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" routerLinkActive="active" [routerLink]="['/dashboard']">{{getTitle()}}</a>
     <!--Form -->
    <form style="width: 28%" class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
      <div style="width: 100%" class="form-group mb-0" [ngClass]="{ 'focused': focus === true }">
        <mat-form-field style="background: white; border-radius: 3px; height: 50px; width: 100%;" appearance="fill">
          <mat-label>Orto</mat-label>
          <mat-select (selectionChange)="cambiaOrto()"  [(value)]="idOrtoS">
            <mat-option  *ngFor="let orto of listOrti"[value]="orto.id">
               {{orto.codice +' --- ' }}{{orto.data_inizio + ' / ' + orto.data_fine}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <!-- User -->
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="assets/img/theme/user-icon.jpg">
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{name}}</span>
            </div>
          </div>
        </a>
        <div style="z-index: 9999999" class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
         <!-- <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>-->
          <!--<a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>Settings</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-calendar-grid-58"></i>
            <span>Activity</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>Support</span>
          </a>-->
          <!--<div class="dropdown-divider"></div>-->
          <a  role="button" (click)="logout()" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
          <a  role="button" (click)="tutorial(myDialog)" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Video Tutorial</span>
          </a>
        </div>
      </li>
    </ul>
  </div>

</nav>
<ng-template #myDialog>
  <div class="popup-container" style="background: linear-gradient(87deg, #a8882b 0, #403b1b 100%) !important">
    <!-- Dialog template -->
    <div class="row">
      <div class="col-xl-12 col-lg-12" >
        <video controls width="800" height="600">
          <source src="/assets/video/VideoOrto.mp4" type="video/mp4">
          Il tuo browser non supporta il tag video.
        </video>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12" >
      </div>
    </div>
  </div>
</ng-template>


