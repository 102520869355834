import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { SelectArticoloComponent } from './select-articolo/select-articolo.component';
import {OrtoEditComponent} from './orto-edit/orto-edit.component';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { OrtoEditDialogComponent } from './orto-edit-dialog/orto-edit-dialog.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatSliderModule} from '@angular/material/slider';
import { PopupVideoComponent } from './popup-video/popup-video.component';
import { UserArticleComponent } from './user-article/user-article.component';
import { UsersArticleExtraOrtoComponent } from './users-article-extra-orto/users-article-extra-orto.component';
import {MatTableModule} from '@angular/material/table';
import { OrtoValueObiEditComponent } from './orto-value-obi-edit/orto-value-obi-edit.component';
import {MatSortModule} from '@angular/material/sort';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    MatFormFieldModule,
    MatTabsModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatButtonToggleModule,
    NgxSpinnerModule,
    MatSliderModule,
    MatTableModule,
    MatSortModule,

  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    DialogBoxComponent,
    OrtoEditComponent,
    SelectArticoloComponent,
    SelectArticoloComponent,
    OrtoEditDialogComponent,
    PopupVideoComponent,
    UserArticleComponent,
    UsersArticleExtraOrtoComponent,
    OrtoValueObiEditComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    OrtoEditComponent,
    PopupVideoComponent,
    UsersArticleExtraOrtoComponent
  ]
})
export class ComponentsModule { }
