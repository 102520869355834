import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Categoriaarticolo} from './models/categoriaarticolo.model';
import {environment} from '../environments/environment';
import {Gruppoarticolo} from './models/gruppoarticolo.model';
import {ResponseCustom} from './models/ResponseCustom.model';
import {Articolo} from './models/articolo.model';
import {DomSanitizer} from '@angular/platform-browser';
import {Orto} from './models/orto.model';
import {UserInfo} from './models/user-info.model';
import {Pianta} from './models/pianta.model';
import {Solco} from './models/solco.model';
import {RiepilogoOrtaggi} from './models/riepilogoOrtaggi.model';
import {ValoreOrto} from './models/ValoreOrto.model';
import {ValoreOrtoObi} from './models/ValoreOrtoObi.model';
import {Userarticolo} from './models/userarticolo.model';



@Injectable({
  providedIn: 'root'
})
export class OrtoService {
  @Output() notifyOrto: EventEmitter<any> = new EventEmitter();
  debug = environment.production ? '' : '?XDEBUG_SESSION_START=PHPSTORM';
  debugGet = environment.production ? '' : '&XDEBUG_SESSION_START=PHPSTORM&';
  constructor(public http: HttpClient, private sanitizer: DomSanitizer) {
  }
  /*login*/
  login(username, password) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<UserInfo>>(environment.backendUrl + 'login' + this.debug, {
      username : username,
      password : password
    }, options);
  }
  /*logout*/
  logout() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<UserInfo>>(environment.backendUrl + 'logout' + this.debug, {
    }, options);
  }
  /*login*/
  checkLogin() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<UserInfo>>(environment.backendUrl + 'checklogin' + this.debug, {
    }, options);
  }
  /*categoriaArticoli*/
  getListCategoriaArticoli(): Observable<ResponseCustom<Categoriaarticolo[]>> {
    return this.http.get<ResponseCustom<Categoriaarticolo[]>>( (environment.backendUrl + 'getCategorieArticoli' + this.debug ));
  }

  /*lista piante*/
  getListPiante(id_user: string, mese: number, anno: number, stato: number, idOrto: number): Observable<ResponseCustom<Pianta[]>> {
    return this.http.get<ResponseCustom<Pianta[]>>( (environment.backendUrl + 'getListPiante?id_user=' + id_user + '&mese=' + mese + '&anno=' + anno + '&idorto=' + idOrto + '&stato=' + stato  + this.debugGet ));
  }
  addOrUpdateCategoriaArticoli(categoriaArticolo: Categoriaarticolo) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      const options = { headers: headers };
      return this.http.post<ResponseCustom<Categoriaarticolo[]>>(environment.backendUrl + 'addOrUpdateCategoriaArticoli' + this.debug, {
        request: 'addCategorieArticoli',
        categoriaArticolo : categoriaArticolo
      }, options);
    } catch (error) {
      console.error(error);
    }

  }
  deleteCategoriaArticoli(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Categoriaarticolo[]>>(environment.backendUrl + 'deleteCategoriaArticoli' + this.debug, {
      id : id
    }, options);
  }

  /*gruppoArticoli*/
  getListGruppoArticoli(): Observable<ResponseCustom<Gruppoarticolo[]>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ResponseCustom<Gruppoarticolo[]>>( (environment.backendUrl + 'getGruppiArticoli' + this.debug));
  }
  addOrUpdateGruppoArticoli(gruppoArticolo: Gruppoarticolo) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Gruppoarticolo[]>>(environment.backendUrl + 'addOrUpdateGruppiArticoli' + this.debug, {
      request: 'addCategorieArticoli',
      gruppoArticolo : gruppoArticolo
    }, options);
  }
  deleteGruppoArticoli(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Gruppoarticolo[]>>(environment.backendUrl + 'deleteGruppiArticoli' + this.debug, {
      id : id
    }, options);
  }
  /*Articolo*/

  getListArticolo(id_categoria = null, id_gruppo = null, codice = null, descrizione = null, tipologia = null)
    : Observable<ResponseCustom<Articolo[]>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ResponseCustom<Articolo[]>>(environment.backendUrl + 'getArticoli?id_categoria=' + id_categoria +
      '&id_gruppo=' + id_gruppo +
      '&codice=' + codice +
      '&descrizione=' + descrizione +
      '&tipologia=' + tipologia +
      this.debugGet);
  }
  getListUserArticoli(id_user)
    : Observable<ResponseCustom<Userarticolo[]>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ResponseCustom<Userarticolo[]>>(environment.backendUrl + 'getListUserArticoli?id_user=' + id_user +
      this.debugGet);
  }
  addOrUpdateArticolo(articolo: Articolo, foto: String, icona: String, iconaAccrescimento: String, iconaRaccolta: String) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Articolo[]>>(environment.backendUrl + 'addOrUpdateArticolo' + this.debug, {
      articolo : articolo,
      foto : foto,
      icona : icona,
      icona_raccolta : iconaRaccolta,
      icona_accrescimento : iconaAccrescimento
    }, options);
  }
  updateUserArticolo(id_user, id_articolo, selected) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Articolo[]>>(environment.backendUrl + 'updateUserArticolo' + this.debug, {
      id_user : id_user,
      id_articolo : id_articolo,
      selected : selected
    }, options);
  }

  deleteArticolo(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Articolo[]>>(environment.backendUrl + 'deleteArticolo' + this.debug, {
      id : id
    }, options);
  }

  getFoto(idArticolo: number): Observable<ResponseCustom<String>> {
    return this.http.get<ResponseCustom<String>>( (environment.backendUrl + 'getFotoArticolo?idArticolo=' + idArticolo + this.debugGet));
  }

  getIcona(idArticolo: number): Observable<ResponseCustom<String>> {
    return this.http.get<ResponseCustom<String>>( (environment.backendUrl + 'getIconaArticolo?idArticolo=' + idArticolo + this.debugGet ));
  }
  /*orto*/
  getListOrti(standard = 1, id_user = null): Observable<ResponseCustom<Orto[]>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ResponseCustom<Orto[]>>( (environment.backendUrl + 'getListOrti?standard=' + standard + '&id_user=' + id_user + this.debugGet));
  }

  // Valore Orto
  getValoreOrti(id_orto: number): Observable<ResponseCustom<any>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ResponseCustom<any>>((environment.backendUrl + 'getListValoreOrto?id_orto=' + id_orto + this.debugGet));
  }


  updateValoreOrto (valoreOrto: { valore: {id: number; id_orto: number; anno: number; valore: number; mese: number; } }) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<ValoreOrto>>(environment.backendUrl + 'updateValoreOrto' + this.debug, {
      valoreOrto : valoreOrto,
    }, options);
  }

  updateValoreOrtoObiettivo (valoreOrto: { valore: {id: number; id_orto: number; anno: number; valore_obb: number; mese: number; } }) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<ValoreOrto>>(environment.backendUrl + 'updateValoreOrtoObiettivo' + this.debug, {
      valoreOrto : valoreOrto,
    }, options);
  }


  // Valore Orto Obiettivo

  // getListValoreOrtoObiettivo(id_orto: number): Observable<ResponseCustom<any>> {
  //   // tslint:disable-next-line:max-line-length
  //   return this.http.get<ResponseCustom<any>>((environment.backendUrl + 'getListValoreOrtoObiettivo?id_orto=' + id_orto + this.debugGet));
  // }


  // updateValoreOrtoObiettivo (valoreOrtoObi: { valore: {id: number; id_orto: number; anno: number; valore: number; mese: number; } }) {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //   });
  //   const options = { headers: headers };
  //   return this.http.post<ResponseCustom<ValoreOrtoObi>>(environment.backendUrl + 'updateValoreOrtoObiettivo' + this.debug, {
  //     valoreOrtoObi : valoreOrtoObi,
  //   }, options);
  // }

  cambiaPrezzoOrto (id_orto: number, prezzo: number ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<ValoreOrto>>(environment.backendUrl + 'cambiaPrezzoOrto' + this.debug, {
      id_orto : id_orto,
      prezzo : prezzo,
    }, options);
  }




  /*orto*/
  getListUtenti(): Observable<ResponseCustom<UserInfo[]>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ResponseCustom<UserInfo[]>>( (environment.backendUrl + 'getListUser' + this.debug));
  }

  deleteOrto(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Orto[]>>(environment.backendUrl + 'deleteOrto' + this.debug, {
      id : id
    }, options);
  }

  addOrtoStandard(orto: Orto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Orto>>(environment.backendUrl + 'addOrtoStandard' + this.debug, {
      orto : orto,
    }, options);
  }

  getOrto(id_orto: number): Observable<ResponseCustom<Orto>> {
    return this.http.get<ResponseCustom<Orto>>( (environment.backendUrl + 'getOrto?id_orto=' + id_orto + this.debugGet));
  }

  getArticolo(id_articolo: number): Observable<ResponseCustom<Articolo>> {
    return this.http.get<ResponseCustom<Articolo>>( (environment.backendUrl + 'getArticolo?id_articolo=' + id_articolo + this.debugGet));
  }
  addOrUpdatePianta(pianta: Pianta) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Pianta[]>>(environment.backendUrl + 'addOrUpdatePianta' + this.debug, {
      pianta : pianta
    }, options);
  }
  addListPianta(pianta: Pianta, qty: number = 1) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Pianta[]>>(environment.backendUrl + 'addListPianta' + this.debug, {
      pianta : pianta,
      qty: qty
    }, options);
  }

  updateSolco(solco: Solco) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Solco>>(environment.backendUrl + 'updateSolco' + this.debug, {
      solco : solco,
    }, options);
  }
  deletePianta(pianta: Pianta) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Solco>>(environment.backendUrl + 'deletePianta' + this.debug, {
      pianta : pianta,
    }, options);
  }
  duplicateSolchiMese(id_orto, mese) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<number>>(environment.backendUrl + 'duplicateSolchiMese' + this.debug, {
      id_orto : id_orto,
      mese : mese
    }, options);
  }
  sviluppaPiante(id_orto, mese) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<number>>(environment.backendUrl + 'sviluppa' + this.debug, {
      id_orto : id_orto,
      mese : mese
    }, options);
  }
  /*updateOrto*/
  updateOrto(orto: Orto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Orto>>(environment.backendUrl + 'updateOrto' + this.debug, {
      orto : orto,
    }, options);
  }
  /*lista ortaggi*/
  getListRiepilogoOrtaggi(mese: number, anno: number): Observable<ResponseCustom<RiepilogoOrtaggi[]>> {
    return this.http.get<ResponseCustom<RiepilogoOrtaggi[]>>( environment.backendUrl + 'getListRiepilogoOrtaggi?mese=' +
      mese + '&anno=' + anno  + this.debugGet );
  }

  changeOrtoSelected(idOrtoS) {
    this.notifyOrto.emit(idOrtoS);
  }


  assegnaOrto(idUser, idOrto, anno, mese  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Orto>>(environment.backendUrl + 'assegnaOrto' + this.debug, {
      id_utente : idUser,
      id_ortostandard : idOrto,
      anno : anno,
      mese : mese
    }, options);
  }

  rinnovaOrto(idOrto, anno, mese  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post<ResponseCustom<Orto>>(environment.backendUrl + 'rinnova' + this.debug, {
      id_orto : idOrto,
      anno : anno,
      mese : mese
    }, options);
  }


}
