import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { MatTableModule } from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { OrtiUtentiComponent } from './pages/orti-utenti/orti-utenti.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { GestioneOrtiComponent } from './pages/gestione-orti/gestione-orti.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { NgxSpinnerModule } from 'ngx-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { RiepilogoOrtaggiComponent } from './pages/riepilogo-ortaggi/riepilogo-ortaggi.component';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import { GestioneOrtiMinComponent } from './pages/gestione-orti-min/gestione-orti-min.component';
import {MatSelectModule} from '@angular/material/select';
import { LoginWordpressComponent } from './pages/login-wordpress/login-wordpress.component';
import { TutorialComponent } from './pages/tutorial/tutorial.component';
import { OrtoValueEditComponent } from './components/orto-value-edit/orto-value-edit.component';
import { UsersComponent } from './pages/users/users.component';
import {MatSortModule} from '@angular/material/sort';
import { UserArticleComponent } from './pages/user-article/user-article.component';
import { RinnovoOrtoComponent } from './pages/rinnovo-orto/rinnovo-orto.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgApexchartsModule } from 'ng-apexcharts';
import {MatSliderModule} from '@angular/material/slider';
import {ValoreOrtoChartComponent} from './components/valore-orto-chart/valore-orto-chart.component';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader
} from '@angular/material/expansion';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    MatTableModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    NgxSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatSortModule,
    BrowserModule,
    NgApexchartsModule,
    MatSliderModule,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatExpansionPanel,
    MatAccordion
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    OrtiUtentiComponent,
    GestioneOrtiComponent,
    RiepilogoOrtaggiComponent,
    GestioneOrtiMinComponent,
    LoginWordpressComponent,
    TutorialComponent,
    OrtoValueEditComponent,
    UserArticleComponent,
    UsersComponent,
    RinnovoOrtoComponent,
    ValoreOrtoChartComponent,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
