import {Component, OnInit, ElementRef, Output, EventEmitter, TemplateRef} from '@angular/core';
import { ROUTES_ADMIN } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import {OrtoService} from '../../orto.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Orto} from '../../models/orto.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  name = sessionStorage.getItem('username');
  public listOrti: Orto[] = [];
  public idOrtoS = null;
  public focus;
  public listTitles: any[];
  public location: Location;
  hidePopup: boolean;
  constructor(location: Location,  private element: ElementRef, private router: Router,
              public ortoService: OrtoService, private snackBar: MatSnackBar, public dialog: MatDialog) {
    this.location = location;
  }

  ngOnInit() {
    this.hidePopup = JSON.parse(localStorage.getItem('hidePopup')) || false;
    if (!this.hidePopup) {
      this.showPopup();
    }
    this.listTitles = ROUTES_ADMIN.filter(listTitle => listTitle);
    this.ortoService.getListOrti(0, sessionStorage.getItem('iduser')).subscribe(resp => {
        if ( resp != null ) {
          if (resp.codice === 0) {
            this.listOrti = resp.resp;
            const listOrti = resp.resp;
            if (listOrti != null && listOrti.length === 1) {
              this.idOrtoS = listOrti[0].id;
              this.idOrtoS = listOrti[0].id;
            } else if (listOrti != null && listOrti.length > 1) {
              const dateNow = new Date();
              let idOrto;
              listOrti.forEach(function (orto, indexGruppo) {
                const dataIn = new Date(orto.data_inizio);
                const dataFi = new Date(orto.data_fine);
                const idOrtoSelezionato = sessionStorage.getItem('id_orto_sel');
                if (idOrtoSelezionato != null ) {
                  idOrto = +idOrtoSelezionato;
                } else if (dataIn < dateNow && dataFi > dateNow) {
                  idOrto = orto.id;
                  sessionStorage.setItem('id_orto_sel', String(orto.id));
                  return;
                }
              });
              this.idOrtoS = idOrto;
              sessionStorage.setItem('id_orto_sel', idOrto);
            }


          } else {
            this.snackBar.open('Errore  : ' + resp.descrizione, 'Chiudi', {
              duration: 10000,
              verticalPosition: 'top',
              horizontalPosition: 'end',
              panelClass: ['error-snackbar']
            });
          }
        }
      },
      error => {
        if (error.status === 401 ) {
          this.router.navigate(['/login']);
        }
        console.log(error);
      });
  }
  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
        titlee = titlee.slice( 1 );
    }

    for (let item = 0; item < this.listTitles.length; item++) {
        if (this.listTitles[item].path === titlee) {
            return this.listTitles[item].title;
        }
    }
    return 'Dashboard';
  }
  logout() {
    try {
      this.ortoService.logout().subscribe(resp => {
        if ( resp != null ) {
          sessionStorage.removeItem('username');
          sessionStorage.removeItem('email');
          sessionStorage.removeItem('isadmin');
          sessionStorage.removeItem('isoperator');
          sessionStorage.removeItem('iduser');
          sessionStorage.clear();
          window.sessionStorage.clear();
          /*this.router.navigate(['/login']);*/
          window.location.href = 'https://www.tenutaaradeltufo.it';
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  tutorial(templateRef: TemplateRef<any>) {
      this.dialog.open(templateRef);
  }

  closeTutorial() {
    if (this.hidePopup) {
      localStorage.setItem('hidePopup', JSON.stringify(true));
    }
    // Logica per chiudere il popup
  }
  showPopup() {
  }

  cambiaOrto() {
    sessionStorage.setItem('id_orto_sel', this.idOrtoS);
    this.ortoService.changeOrtoSelected(this.idOrtoS);
  }
}
