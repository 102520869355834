import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TutorialComponent} from "../../pages/tutorial/tutorial.component";

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES_ADMIN: RouteInfo[] = [
    { path: '/categoria-articoli', title: 'Categoria Articoli',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/gruppo-articoli', title: 'Gruppi Articoli',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/articoli', title: 'Articoli',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/orti', title: 'Orti Standard',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/orti-utenti', title: 'Orti Utenti',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/riepilogo-ortaggi', title: 'Riepilogo Ortaggi',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/tutorial', title: 'Video Tutorial',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/gestione-orti', title: 'Gestione Orti',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/users', title: 'Utenti',  icon: 'ni-tv-2 text-primary', class: '' },

    { path: '/user-article', title: 'Prodotti Extra Orto',  icon: 'ni-cart text-primary', class: '' },
];
export const ROUTES_USER: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
  { path: '/gestione-orti', title: 'Gestione Orti',  icon: 'ni-tv-2 text-primary', class: '' },
  { path: '/tutorial', title: 'Video Tutorial',  icon: 'ni-tv-2 text-primary', class: '' },
  { path: '/user-article', title: 'Prodotti Extra Orto',  icon: 'ni-cart text-primary', class: '' },

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  test: Date = new Date();
  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit() {
    if ( sessionStorage.getItem('isadmin') === '1' || sessionStorage.getItem('isoperator') === '1') {
      this.menuItems = ROUTES_ADMIN.filter(menuItem => menuItem);
    } else {
      this.menuItems = ROUTES_USER.filter(menuItem => menuItem);
    }

    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
}
